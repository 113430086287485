import { template as template_be2d86eadf354deaa9ffe1c38bcd15a6 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import type CleverTeacher from 'district-ui-client/models/clever/clever-teacher';
import CleverUiModal from 'district-ui-client/components/clever-ui/modal';
import CleverUiConfirmDialog from 'district-ui-client/components/clever-ui/confirm-dialog/component';
/**
 * Clever teacher's email contains one or more invalid characters.
 */ interface Signature {
    Args: {
        cleverTeacher: CleverTeacher;
        isShowingModal?: boolean;
        positiveAction: () => void;
        negativeAction: () => void;
    };
}
export const InvalidEmailCharTeacherMatcherModal: TOC<Signature> = template_be2d86eadf354deaa9ffe1c38bcd15a6(`
  <CleverUiModal
    @isShowingModal={{@isShowingModal}}
    @onClose={{@negativeAction}}
    @title={{t "clever.teacherMatcher.errors.invalidEmailChar.summary"}}
    @type="warning"
  >
    <CleverUiConfirmDialog
      @positiveAction={{@positiveAction}}
      @positiveButtonText={{t "close"}}
      @positiveButtonAppearance="orange"
    >
      <p class="text-base">
        {{t
          "clever.teacherMatcher.errors.invalidEmailChar.explanation"
          htmlSafe=true
          firstName=@cleverTeacher.firstName
          lastName=@cleverTeacher.lastName
          email=@cleverTeacher.email
        }}
      </p>
      <p class="mb-0 text-base">
        {{t "clever.teacherMatcher.errors.invalidEmailChar.solution"}}
      </p>
    </CleverUiConfirmDialog>
  </CleverUiModal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InvalidEmailCharTeacherMatcherModal;
