import { template as template_2a8a5c324b494173b0eaade7e46af7b5 } from "@ember/template-compiler";
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { DashboardControls } from 'district-ui-client/components/reporting/dashboard-controls';
import { ReportingDistrictHeader } from 'district-ui-client/components/reporting/district-header';
import { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import type RexDashboardTableRoute from 'district-ui-client/reporting/ui-scope/rex/dashboard/table/route';
import type EmailAllStudentsCsvService from 'district-ui-client/services/email-all-students-csv';
import type SessionService from 'district-ui-client/services/session';
import { statefulSort } from 'district-ui-client/utils/giraffe/stateful-sort';
import DataTableOperations from '@blakeelearning/data-tables/components/data-table-operations/index';
import { t } from 'ember-intl';
import { eq, gt } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { thousands } from 'district-ui-client/helpers/thousands';
import { formatValue } from 'district-ui-client/utils/format-value';
import formatHumanizedDuration from 'district-ui-client/helpers/format-humanized-duration';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        model: ModelFor<RexDashboardTableRoute>;
    };
}
export class ReportingRexDashboardRouteTemple extends Component<Signature> {
    @service
    emailAllStudentsCsv: EmailAllStudentsCsvService;
    @service
    session: SessionService;
    @tracked
    currentSortColumn = null;
    @tracked
    currentSortIcon: IconName = 'sort';
    get isSummaryDataPresent() {
        return isPresent(this.args.model.summary) && isPresent(this.args.model.summary?.summary.schools);
    }
    get rows() {
        const schools = this.args.model.scopedSchools;
        const summaryStats = this.args.model.summary?.summary?.schools ?? [];
        return schools.map((school)=>{
            const statsForSchool = summaryStats.find((stat)=>String(stat.school_id) === school.id);
            const schoolWithStats = {
                school_name: school.name,
                contributing_students: school.utilisationFor(SubscriptionType.Reading)?.studentCount ?? 0,
                ...statsForSchool
            };
            return schoolWithStats;
        });
    }
    get summaryRow() {
        return {
            ...(this.args.model.summary?.summary.district || {}),
            contributing_students: this.args.model.scopedSchools.reduce((total, school)=>total + (school.utilisationFor(SubscriptionType.Reading)?.studentCount ?? 0), 0)
        };
    }
    statefulSort = (...args: Parameters<typeof statefulSort>)=>{
        return statefulSort.apply(this, args);
    };
    emailAllStudentsCSV = ()=>{
        const districtId = this.session.currentDistrict.id;
        const product = this.args.model.product;
        const period = this.args.model.period;
        void this.emailAllStudentsCsv.emailAllStudentsCSV(districtId, product, period);
    };
    static{
        template_2a8a5c324b494173b0eaade7e46af7b5(`
    <ReportingDistrictHeader />
    <DashboardControls
      class="my-3"
      @viewMode="table"
      @isDataPresent={{this.isSummaryDataPresent}}
      @emailAllStudentsCSV={{this.emailAllStudentsCSV}}
    />
    <div class="overflow-x-auto">
      <DataTableOperations @data={{this.rows}} as |transformedRows tableAction|>
        <table data-test-dash-table class="disco-table w-full bg-white text-center text-sm" tabindex="0" exportable>
          <thead class="divide-y divide-white">
            <tr>
              <th>{{t "reporting.uiScope.rex.dashboard.table.school"}}</th>
              <th>{{t "reporting.uiScope.rex.dashboard.table.activeStudents"}}</th>
              <th colspan="4">{{t "reporting.uiScope.rex.dashboard.table.totalCompleted AvgPerStud"}}</th>
              <th>{{t "reporting.uiScope.rex.dashboard.table.initialLexile"}}</th>
              <th>{{t "reporting.uiScope.rex.dashboard.table.highestLexile"}}</th>
              <th>{{t "reporting.uiScope.rex.dashboard.table.lexileGrowth"}}</th>
              <th colspan="4">{{t "reporting.uiScope.rex.dashboard.table.booksReadByLevel"}}</th>
              <th colspan="2">{{t "reporting.uiScope.rex.dashboard.table.booksReadByGenre"}}</th>
              <th>{{t "reporting.uiScope.rex.dashboard.table.stadium"}}</th>
              <th>{{t "reporting.uiScope.rex.dashboard.table.usage"}}</th>
              <th>
                {{t "reporting.district.dashboard.table.totalTimeInProgram"}}
              </th>
            </tr>
            <tr>
              <th
                class={{if (eq this.currentSortColumn "school_name") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByValue" "school_name")}}
              >
                <div class="flex items-center justify-end">
                  <FaIcon @icon={{if (eq this.currentSortColumn "school_name") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "contributing_students") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "contributing_students")}}
              >
                <div class="flex items-center justify-end">
                  <FaIcon
                    @icon={{if (eq this.currentSortColumn "contributing_students") this.currentSortIcon "sort"}}
                  />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "lessons_completed") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "lessons_completed")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.rex.dashboard.table.comprehensionLessons"
                  }}<FaIcon @icon={{if (eq this.currentSortColumn "lessons_completed") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "spelling_completed") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "spelling_completed")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.rex.dashboard.table.spellingLessons"
                  }}<FaIcon @icon={{if (eq this.currentSortColumn "spelling_completed") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "books_read") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "books_read")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.rex.dashboard.table.booksRead"
                  }}<FaIcon @icon={{if (eq this.currentSortColumn "books_read") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "assessments") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "assessments")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.rex.dashboard.table.assessmentsCompleted"
                  }}<FaIcon @icon={{if (eq this.currentSortColumn "assessments") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "average_initial_lesson_lexile_per_student") "selected"}}
                role="button"
                {{on
                  "click"
                  (fn this.statefulSort tableAction "sortByNumber" "average_initial_lesson_lexile_per_student")
                }}
              >
                <div class="flex items-center justify-end">
                  <FaIcon
                    @icon={{if
                      (eq this.currentSortColumn "average_initial_lesson_lexile_per_student")
                      this.currentSortIcon
                      "sort"
                    }}
                  />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "average_current_lesson_lexile_per_student") "selected"}}
                role="button"
                {{on
                  "click"
                  (fn this.statefulSort tableAction "sortByNumber" "average_current_lesson_lexile_per_student")
                }}
              >
                <div class="flex items-center justify-end">
                  <FaIcon
                    @icon={{if
                      (eq this.currentSortColumn "average_current_lesson_lexile_per_student")
                      this.currentSortIcon
                      "sort"
                    }}
                  />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "lexile_growth") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "lexile_growth")}}
              >
                <div class="flex items-center justify-end">
                  <FaIcon @icon={{if (eq this.currentSortColumn "lexile_growth") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "books_read_0_300") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "books_read_0_300")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.rex.dashboard.table.0300L"
                  }}<FaIcon @icon={{if (eq this.currentSortColumn "books_read_0_300") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "books_read_300_500") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "books_read_300_500")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.rex.dashboard.table.300500L"
                  }}<FaIcon @icon={{if (eq this.currentSortColumn "books_read_300_500") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "books_read_500_800") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "books_read_500_800")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.rex.dashboard.table.500800L"
                  }}<FaIcon @icon={{if (eq this.currentSortColumn "books_read_500_800") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "books_read_800_2000") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "books_read_800_2000")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.rex.dashboard.table.8002000L"
                  }}<FaIcon
                    @icon={{if (eq this.currentSortColumn "books_read_800_2000") this.currentSortIcon "sort"}}
                  />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "books_read_fiction") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "books_read_fiction")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.rex.dashboard.table.fiction"
                  }}<FaIcon @icon={{if (eq this.currentSortColumn "books_read_fiction") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "books_read_nonfiction") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "books_read_nonfiction")}}
              >
                <div class="flex items-center justify-between gap-2">{{t
                    "reporting.uiScope.rex.dashboard.table.nonFiction"
                  }}<FaIcon
                    @icon={{if (eq this.currentSortColumn "books_read_nonfiction") this.currentSortIcon "sort"}}
                  />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "stadium_events") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "stadium_events")}}
              >
                <div class="flex items-center justify-end">
                  <FaIcon @icon={{if (eq this.currentSortColumn "stadium_events") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "total_usage") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "total_usage")}}
              >
                <div class="flex items-center justify-end">
                  <FaIcon @icon={{if (eq this.currentSortColumn "total_usage") this.currentSortIcon "sort"}} />
                </div>
              </th>
              <th
                class={{if (eq this.currentSortColumn "total_time") "selected"}}
                role="button"
                {{on "click" (fn this.statefulSort tableAction "sortByNumber" "total_time")}}
              >
                <div class="flex items-center justify-end">
                  <FaIcon @icon={{if (eq this.currentSortColumn "total_time") this.currentSortIcon "sort"}} />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {{#if (gt @model.scopedSchools.length 1)}}
              <tr data-fixed class="font-semibold">
                <td>{{t "reporting.uiScope.rex.dashboard.table.allSchools"}}</td>
                <td>
                  {{thousands this.summaryRow.contributing_students}}
                </td>
                <td>
                  {{thousands this.summaryRow.lessons_completed}}
                  |
                  {{formatValue this.summaryRow.average_lessons_completed_per_student}}
                </td>
                <td>
                  {{thousands this.summaryRow.spelling_completed}}
                  |
                  {{formatValue this.summaryRow.average_spelling_completed_per_student}}
                </td>
                <td>
                  {{thousands this.summaryRow.books_read}}
                  |
                  {{formatValue this.summaryRow.average_books_read_per_student}}
                </td>
                <td>
                  {{thousands this.summaryRow.assessments}}
                  |
                  {{formatValue this.summaryRow.average_assessments_per_student}}
                </td>
                <td>
                  {{thousands this.summaryRow.average_initial_lesson_lexile_per_student}}
                </td>
                <td>
                  {{thousands this.summaryRow.average_current_lesson_lexile_per_student}}
                </td>
                <td>
                  {{thousands this.summaryRow.lexile_growth}}
                </td>
                <td>
                  {{thousands this.summaryRow.books_read_0_300}}
                </td>
                <td>
                  {{thousands this.summaryRow.books_read_300_500}}
                </td>
                <td>
                  {{thousands this.summaryRow.books_read_500_800}}
                </td>
                <td>
                  {{thousands this.summaryRow.books_read_800_2000}}
                </td>
                <td>
                  {{thousands this.summaryRow.books_read_fiction}}
                </td>
                <td>
                  {{thousands this.summaryRow.books_read_nonfiction}}
                </td>
                <td>
                  {{thousands this.summaryRow.stadium_events}}
                </td>
                <td>
                  {{thousands this.summaryRow.total_usage}}
                </td>
                <td>
                  {{formatHumanizedDuration this.summaryRow.total_time "-"}}
                </td>
              </tr>
            {{/if}}
            {{#each transformedRows as |row|}}
              <tr>
                <td>
                  {{row.school_name}}
                </td>
                <td>
                  {{thousands row.contributing_students}}
                </td>
                <td>
                  {{thousands row.lessons_completed}}
                  |
                  {{formatValue row.average_lessons_completed_per_student}}
                </td>
                <td>
                  {{thousands row.spelling_completed}}
                  |
                  {{formatValue row.average_spelling_completed_per_student}}
                </td>
                <td>
                  {{thousands row.books_read}}
                  |
                  {{formatValue row.average_books_read_per_student}}
                </td>
                <td>
                  {{thousands row.assessments}}
                  |
                  {{formatValue row.average_assessments_per_student}}
                </td>
                <td>
                  {{thousands row.average_initial_lesson_lexile_per_student}}
                </td>
                <td>
                  {{thousands row.average_current_lesson_lexile_per_student}}
                </td>
                <td>
                  {{thousands row.lexile_growth}}
                </td>
                <td>
                  {{thousands row.books_read_0_300}}
                </td>
                <td>
                  {{thousands row.books_read_300_500}}
                </td>
                <td>
                  {{thousands row.books_read_500_800}}
                </td>
                <td>
                  {{thousands row.books_read_800_2000}}
                </td>
                <td>
                  {{thousands row.books_read_fiction}}
                </td>
                <td>
                  {{thousands row.books_read_nonfiction}}
                </td>
                <td>
                  {{thousands row.stadium_events}}
                </td>
                <td>
                  {{thousands row.total_usage}}
                </td>
                <td>
                  {{formatHumanizedDuration row.total_time "-"}}
                </td>
              </tr>
            {{/each}}
          </tbody>
        </table>
      </DataTableOperations>
    </div>

    <p class="bg-white p-1 font-bold">
      {{t "reporting.uiScope.rex.dashboard.table.UsageTotalCountOfLessonsC"}}
    </p>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ReportingRexDashboardRouteTemple);
