import { template as template_1eda436c52e944d39eda7e6db91f1f90 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
import FidgetLoadingIndicatorComponent from '@blakeelearning/fidget/components/fidget/loading-indicator';
import { t } from 'ember-intl';
/**
 * Display a loading spinner on child pages of subscription-type route while their model loads
 */ const SubscriptionTypeLoading: TOC<unknown> = template_1eda436c52e944d39eda7e6db91f1f90(`
  <div class="px-8 py-10">
    <FidgetLoadingIndicatorComponent
      @show={{true}}
      @overlay={{false}}
      @loadingText={{t "loading"}}
      @centered={{true}}
    />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(SubscriptionTypeLoading);
