import { template as template_36c828143db3439487b7de3817b52805 } from "@ember/template-compiler";
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { not } from 'ember-truth-helpers';
import { ChartsQuizScoresTotals } from 'district-ui-client/components/charts/quiz-scores/totals/component';
import fullGradeName from 'district-ui-client/helpers/full-grade-name';
import { t } from 'ember-intl';
import type RexQuizScoresRoute from 'district-ui-client/reporting/ui-scope/rex/quiz-scores/route';
import type { TOC } from '@ember/component/template-only';
import RouteTemplate from 'ember-route-template';
interface Signature {
    model: ModelFor<RexQuizScoresRoute>;
}
export const RexQuizScoresRouteTemplate: TOC<Signature> = template_36c828143db3439487b7de3817b52805(`
  <DetailedPageWrapper @empty={{not @model.quizScoresTotals}}>
    {{#each @model.quizScoresTotals.quiz_scores_totals as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsQuizScoresTotals
          class="h-full"
          @data={{gradeData}}
          @grade={{fullGradeName gradeData.grade_position}}
          @tooltip={{t "reporting.tooltips.quizScoresTotals"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(RexQuizScoresRouteTemplate);
