import { template as template_c721269ca9be4672b53c6908defc696b } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { hash } from '@ember/helper';
import { BaseModal } from 'district-ui-client/components/base-modal';
import ModalDefaultHeader from 'district-ui-client/components/modal-default/header';
import ModalDefaultBody from 'district-ui-client/components/modal-default/body';
import { ModalDefaultFooter } from 'district-ui-client/components/modal-default/footer';
interface Signature {
    Element: HTMLDivElement;
    Args: {
        onClose: () => unknown;
    };
    Blocks: {
        default: [{
                header: any;
                body: any;
                footer: any;
            }];
    };
}
export const ModalDefault: TOC<Signature> = template_c721269ca9be4672b53c6908defc696b(`
  <BaseModal @close={{@onClose}}>
    <div
      class="border-dusty-black-200 relative rounded-md border border-solid bg-clip-padding outline-0"
      data-test-modal-content
      ...attributes
    >
      {{yield
        (hash
          header=(component ModalDefaultHeader onClose=@onClose)
          body=(component ModalDefaultBody)
          footer=(component ModalDefaultFooter)
        )
      }}
    </div>
  </BaseModal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalDefault;
