import { template as template_ae630341d8994a06970c726b588ece0d } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import { ConfirmWithCheckbox } from 'district-ui-client/components/clever-ui/modals/confirm-with-checkbox';
import type CleverTeacher from 'district-ui-client/models/clever/clever-teacher';
interface Signature {
    Args: {
        isShowingModal?: boolean;
        positiveAction: () => void;
        negativeAction: () => void;
        cleverTeacher: CleverTeacher;
    };
}
export const ExistsAsParentTeacherMatcherModal: TOC<Signature> = template_ae630341d8994a06970c726b588ece0d(`
  <ConfirmWithCheckbox
    @isShowingModal={{@isShowingModal}}
    @title={{t "clever.teacherMatcher.errors.existsAsParent.summary"}}
    @positiveAction={{@positiveAction}}
    @positiveButtonText={{t "clever.teacherMatcher.errors.existsAsParent.positiveButtonText"}}
    @negativeAction={{@negativeAction}}
    @confirmLabel={{t "clever.teacherMatcher.errors.existsAsParent.confirmLabel"}}
  >
    <p class="text-base">
      {{t "clever.teacherMatcher.errors.existsAsParent.explanation" htmlSafe=true email=@cleverTeacher.email}}
    </p>
    <p class="mb-0 text-base">
      {{t "clever.teacherMatcher.errors.existsAsParent.solution"}}
    </p>
  </ConfirmWithCheckbox>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ExistsAsParentTeacherMatcherModal;
