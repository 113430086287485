import { template as template_b913ab9612224f1888833950413dfaad } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export const PaneHeader: TOC<Signature> = template_b913ab9612224f1888833950413dfaad(`
  <div class="flex content-center items-center justify-between py-2.5 text-sm font-semibold uppercase" ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
