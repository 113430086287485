import { template as template_2a4297f887084ab39ae27d529595ca19 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
export const ManagePage: TOC<unknown> = template_2a4297f887084ab39ae27d529595ca19(`
  <div class="px-8 py-10">
    {{outlet}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ManagePage);
