import { template as template_22905a90d2f24c91b1dcd514f0a8f978 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import LoadingSpinner from 'district-ui-client/components/loading-spinner';
import RouteTemplate from 'ember-route-template';
export const LoadingRouteTemplate: TOC<unknown> = template_22905a90d2f24c91b1dcd514f0a8f978(`<LoadingSpinner />`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(LoadingRouteTemplate);
