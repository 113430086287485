import { template as template_911fb118531c4232862832f02f9a2dd5 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { Tooltip } from 'district-ui-client/components/tooltip';
interface Args {
    tooltipContent?: string;
}
interface Signature {
    Element: HTMLSpanElement;
    Args: Args;
}
/**
 * @deprecated - please use TooltipInfo component
 */ export const ChartInfoTooltip: TOC<Signature> = template_911fb118531c4232862832f02f9a2dd5(`
  {{#if @tooltipContent}}
    <span class="z-tooltip absolute right-0.5 top-0 print:hidden" data-test-chart-tooltip ...attributes>
      <FaIcon @icon="circle-info" @size="xs" class="text-dusty-black-500 cursor-pointer" />
      <Tooltip @text={{@tooltipContent}} data-test-tooltip />
    </span>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ChartInfoTooltip;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        ChartInfoTooltip: typeof ChartInfoTooltip;
    }
}
