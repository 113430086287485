import { template as template_e929ce0f2639401098a59dbabd35ad90 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { on } from '@ember/modifier';
import { ManageHeading } from 'district-ui-client/components/manage-heading';
import Panel from 'district-ui-client/components/panel';
import { RolloverAlert } from 'district-ui-client/components/rollover-alert';
import UiButton from 'district-ui-client/components/ui-button';
import type ManageStudentsIndexController from 'district-ui-client/subscription-type/manage/students/index/controller';
import eventValue from 'district-ui-client/helpers/event-value';
import themeKey from 'district-ui-client/helpers/theme-key';
import { t } from 'ember-intl';
import { FormComponentsFormSelect } from 'district-ui-client/components/form-components/form-select';
import FidgetLoadingIndicatorComponent from '@blakeelearning/fidget/components/fidget/loading-indicator';
import StudentTable from 'district-ui-client/components/student-table';
import ManageStudentActions from 'district-ui-client/components/manage-student-actions';
import UiButtonLinkTo from 'district-ui-client/components/ui-button/link-to';
import ExportStudents from 'district-ui-client/components/export-students';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { LinkTo } from '@ember/routing';
import RouteTemplate from 'ember-route-template';
import { hash } from '@ember/helper';
import { InputText } from 'district-ui-client/components/input-text';
interface Signature {
    Args: {
        controller: ManageStudentsIndexController;
    };
}
export const ManageStudentsRouteTemplate: TOC<Signature> = template_e929ce0f2639401098a59dbabd35ad90(`
  <ManageHeading>{{t "subscriptionType.manageStudents.index.manageStudents"}}</ManageHeading>
  <RolloverAlert />
  <div class="my-6 flex justify-between print:hidden">
    <div class="px-3 sm:w-full md:w-1/2 lg:w-1/3">
      <Panel
        @theme={{themeKey @controller.subscriptionType}}
        class="h-full {{if @controller.selectedStudents 'active'}}"
        @title={{t "subscriptionType.manageStudents.index.searchByStudent"}}
      >
        <form
          class="space-y-3"
          aria-label={{t "searchPlaceholder.student"}}
          {{on "submit" @controller.searchByStudent}}
        >
          <div class="flex items-center justify-between">
            <label for="student-first-name" class="w-1/3">{{t
                "subscriptionType.manageStudents.index.firstName"
              }}</label>
            <InputText
              id="student-first-name"
              class="w-2/3"
              type="search"
              placeholder={{t "searchPlaceholder.minimumTwoCharacters"}}
              value={{@controller.queryFirstName}}
              {{on "input" (eventValue @controller.setQueryFirstName)}}
            />
          </div>
          <div class="flex items-center justify-between">
            <label for="student-last-name" class="w-1/3">{{t "subscriptionType.manageStudents.index.lastName"}}</label>
            <InputText
              id="student-last-name"
              class="w-2/3"
              type="search"
              placeholder={{t "searchPlaceholder.minimumTwoCharacters"}}
              value={{@controller.queryLastName}}
              {{on "input" (eventValue @controller.setQueryLastName)}}
            />
          </div>
          <div class="text-right">
            <UiButton id="search-by-student" class="regular" type="submit" disabled={{@controller.byNameDisabled}}>
              {{t "subscriptionType.manageStudents.index.search"}}
            </UiButton>
          </div>
        </form>
      </Panel>
    </div>
    <div class="px-3 sm:w-full md:w-1/2 lg:w-1/3">
      <Panel
        @theme={{themeKey @controller.subscriptionType}}
        class="h-full {{if @controller.selectedTeacherClassOption 'active'}}"
        @title={{t "subscriptionType.manageStudents.index.searchByTeacher"}}
      >
        <FormComponentsFormSelect
          data-test-search-by-teacher
          @defaultText="Select a teacher or class"
          @search={{true}}
          @options={{@controller.sortedTeachersOptions}}
          @searchPlaceholder="Search teachers"
          @optionClick={{@controller.selectTeacherOrSchoolClass}}
          @value={{@controller.selectedTeacherClassOption}}
        />
      </Panel>
    </div>
    <div class="px-3 sm:w-full md:w-1/2 lg:w-1/3">
      <Panel
        @theme={{themeKey @controller.subscriptionType}}
        class="h-full {{if @controller.selectedSchoolOption 'active'}}"
        @title={{t "subscriptionType.manageStudents.index.searchBySchool"}}
      >
        <FormComponentsFormSelect
          data-test-search-by-school
          @defaultText="Select a school"
          @search={{true}}
          @options={{@controller.sortedSchoolsOptions}}
          @searchPlaceholder="Search schools"
          @optionClick={{@controller.selectSchool}}
          @value={{@controller.selectedSchoolOption}}
        />
      </Panel>
    </div>
  </div>
  <div class="h-8">
    {{#if @controller.searchInFlight}}
      <FidgetLoadingIndicatorComponent
        @show={{@controller.searchInFlight}}
        @overlay={{false}}
        @centered={{true}}
        class="relative top-6"
      />
    {{/if}}
  </div>
  {{#if @controller.showResultCount}}
    <div class="my-6 text-center">
      <h2 class="m-0">
        {{t "subscriptionType.manageStudents.index.studentSearchResults"}}
        ({{@controller.productStudents.length}})
      </h2>
    </div>
  {{/if}}
  {{#if @controller.searchResult}}
    <div class="mt-6 text-center">
      <StudentTable
        @students={{@controller.productStudents}}
        @subscriptionType={{@controller.subscriptionType}}
        @isCleverDisabled={{@controller.isCleverDisabled}}
        @selectedIds={{@controller.selectedIds}}
        @updateSelectedIds={{@controller.updateSelectedIds}}
      >
        <ManageStudentActions
          @selectedStudents={{@controller.selectedStudents}}
          @updateSelectedStudents={{@controller.updateSelectedIds}}
          @isDisabled={{@controller.groupActionsDisabled}}
          @schools={{@controller.sortedSchools}}
        />
        <div class="mx-3 mb-3 inline-flex align-middle">
          <UiButtonLinkTo
            @route={{@controller.addStudentRouteName}}
            @query={{hash schoolId=@controller.schoolId}}
            class="muted"
            @corners="rounded rounded-r-none"
            @disabled={{@controller.isCleverDisabled}}
            data-test-new-student-link
          >
            <FaIcon @icon="user" class="mr-0.5" />
            {{t "subscriptionType.manageStudents.index.addStudent"}}
          </UiButtonLinkTo>
          <UiButtonLinkTo
            @route={{@controller.importStudentsRouteName}}
            class="muted"
            @corners="rounded rounded-r-none"
            @disabled={{@controller.isCleverDisabled}}
            data-test-import-students-link
          >
            <FaIcon @icon="users" class="mr-0.5" />
            {{t "importStudents"}}
          </UiButtonLinkTo>

          <ExportStudents @school={{@controller.currentSchoolForExport}} @showAsButton={{true}} />
        </div>
      </StudentTable>
    </div>
  {{else}}
    <div class="mt-6 text-center">
      <FaIcon @icon="magnifying-glass" />
      {{t "performSearch"}}
      <div class="space-x-3">
        {{#unless @controller.isCleverDisabled}}
          <LinkTo
            @route={{@controller.addStudentRouteName}}
            @model={{@controller.subscriptionType}}
            class="text-oceany-blue-300 hover:text-oceany-blue-350 focus:text-oceany-blue-350"
          >
            <FaIcon @icon="user" class="mr-0.5 text-black" />
            {{t "subscriptionType.manageStudents.index.addStudent"}}
          </LinkTo>
          <LinkTo
            @route={{@controller.importStudentsRouteName}}
            @model={{@controller.subscriptionType}}
            class="text-oceany-blue-300 hover:text-oceany-blue-350 focus:text-oceany-blue-350"
          >
            <FaIcon @icon="users" class="mr-0.5 text-black" />
            {{t "importStudents"}}
          </LinkTo>
        {{/unless}}
        <ExportStudents @school={{@controller.currentSchoolForExport}} />
      </div>
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ManageStudentsRouteTemplate);
