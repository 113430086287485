import { template as template_4a7256cc937241688e3a83ad0886b8eb } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { ReportingPageHeader } from 'district-ui-client/components/reporting/page-header';
import { ReportingScopeHeader } from 'district-ui-client/components/reporting/scope-header';
interface Signature {
    Element: HTMLDivElement;
}
export const ReportingDistrictHeader: TOC<Signature> = template_4a7256cc937241688e3a83ad0886b8eb(`
  <ReportingPageHeader class="mb-8" />
  <ReportingScopeHeader />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ReportingDistrictHeader;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::DistrictHeader': typeof ReportingDistrictHeader;
    }
}
