import { template as template_6107021e2a3b4ed6bcccd61c22e9573d } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface PageTitleSignature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export const PageTitle: TOC<PageTitleSignature> = template_6107021e2a3b4ed6bcccd61c22e9573d(`
  <div class="h-10 text-2xl" ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface SectionTitleSignature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
/**
 * Intended for smaller headings of sections, typically not rendered in a panel or tile but just on plain background.
 */ export const SectionTitle: TOC<SectionTitleSignature> = template_6107021e2a3b4ed6bcccd61c22e9573d(`
  <div class="text-lg font-semibold" ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
