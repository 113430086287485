import { template as template_71401647698b41afa1d7d5f5db1a4d6e } from "@ember/template-compiler";
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import Component from '@glimmer/component';
import Tooltip from 'district-ui-client/components/tooltip';
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import { MATCH_STATES, type MatchStates } from 'district-ui-client/utils/clever/clever-school/sync-process-states';
import { t } from 'ember-intl';
interface Signature {
    matchState: MatchStates;
    subscriptionType: SubscriptionType;
}
export class CleverMatchStateIndicator extends Component<Signature> {
    get isTBD() {
        return this.args.matchState === MATCH_STATES.TBD;
    }
    get inProgress() {
        return this.args.matchState === MATCH_STATES.IN_PROGRESS;
    }
    get isDone() {
        return this.args.matchState === MATCH_STATES.DONE;
    }
    get requiresMatchConfirmation() {
        return this.args.matchState === MATCH_STATES.CONFIRM;
    }
    static{
        template_71401647698b41afa1d7d5f5db1a4d6e(`
    <div data-test-match-state-indicator>
      {{#if this.isTBD}}
        <FaIcon
          @icon="circle-xmark"
          class="text-watermelony-red-350"
          aria-label={{t "components.cleverUi.matchStateIndicator.tbd"}}
        />
      {{else if this.inProgress}}
        <FaIcon
          @icon="arrows-rotate"
          @spin={{true}}
          class="text-juicy-orange-350"
          aria-label={{t "components.cleverUi.matchStateIndicator.inProgress"}}
        />
        <div>{{t "components.cleverUi.matchStateIndicator.inProgress"}}</div>
      {{else if this.isDone}}
        <FaIcon
          @icon="circle-check"
          class="text-forest-green-300"
          aria-label={{t "components.cleverUi.matchStateIndicator.done"}}
        />
      {{else if this.requiresMatchConfirmation}}
        <FaIcon
          @icon="circle-info"
          class="text-juicy-orange-350"
          aria-label={{t "components.cleverUi.matchStateIndicator.autoMatched"}}
        />
        <div>{{t "components.cleverUi.matchStateIndicator.autoMatched"}}</div>
        <Tooltip
          @text={{t "components.cleverUi.matchStateIndicator.automatchedInfo" subscription=(t @subscriptionType)}}
        />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default CleverMatchStateIndicator;
