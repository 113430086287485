import { template as template_f7d66336c69a47ea84153bc8791d3bf3 } from "@ember/template-compiler";
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { Interval } from '@blakeelearning/stable-table/utils/st/split-duration';
import { serialize as serializeSort, deserialize as deserializeSort, type SortingConfig } from '@blakeelearning/data-tables/utils/sorting';
import type { IntlService } from 'ember-intl';
import type { ReportingProductSlug } from 'district-ui-client/domain/product';
import type School from 'district-ui-client/models/school';
import { BaseTableActions } from 'district-ui-client/components/base/table-actions';
import { eq } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import type { CellObject } from '@blakeelearning/stable-table/components/basic-table/cell';
export interface SchoolsData {
    school_id: number;
    student_count: number;
    total_time: number;
    lessons_completed: number;
    average_total_time_per_student: number;
    average_lessons_completed_per_student: number;
    average_lexile_growth: number;
}
export interface AveragesAndTotalsData {
    average_total_time_per_school: number;
    average_lessons_completed_per_school: number;
    average_total_time_per_student: number;
    average_lessons_completed_per_student: number;
    average_lexile_growth: number;
    student_count: number;
    lessons_completed: number;
    total_time: number;
}
interface Signature {
    Args: {
        product: ReportingProductSlug;
        schools: School[];
        schoolsData?: SchoolsData[];
        averagesAndTotalsData?: AveragesAndTotalsData;
    };
}
type DataKey = 'schoolName' | 'studentCount' | 'totalTime' | 'lessonsCompleted' | 'averageTotalTimePerStudent' | 'averageLessonsCompletedPerStudent' | 'averageLexileGrowth';
export class SummaryTableSchools extends Component<Signature> {
    @service
    intl: IntlService;
    @tracked
    sort = '';
    get sortingConfig() {
        return deserializeSort(this.sort);
    }
    set sortingConfig(newSortConfig) {
        this.sort = serializeSort(newSortConfig);
    }
    onSortClick = (sortingConfig: SortingConfig)=>{
        this.sortingConfig = sortingConfig;
    };
    /**
   * An array of keys used to identify data columns, also serving as the key/path to data within a row object.
   */ get dataKeys(): DataKey[] {
        const dataKeys: DataKey[] = [
            'schoolName',
            'studentCount',
            'totalTime',
            'lessonsCompleted',
            'averageTotalTimePerStudent',
            'averageLessonsCompletedPerStudent'
        ];
        return this.args.product === 'maths' ? dataKeys : [
            ...dataKeys,
            'averageLexileGrowth'
        ];
    }
    /**
   * Transforms an array of data keys into column objects. The column objects are used by the table to determine where
   * to get data from for each cell in the column, how to sort that column, etc
   *
   * See the BasicTable readme for more info on column objects
   */ get columns() {
        const { dataKeys, comparersForColumns, durationConfig, intl } = this;
        return dataKeys.map((dataKey)=>{
            const value = intl.t(`reporting.district.summaryReportTitles.${this.args.product}.${dataKey}`);
            const isTimeColumn = [
                'totalTime',
                'averageTotalTimePerStudent'
            ].includes(dataKey);
            const replaceZeroColumn = [
                'studentCount',
                'lessonsCompleted',
                'averageLessonsCompletedPerStudent',
                'averageLexileGrowth'
            ].includes(dataKey);
            const headerClasses = this.headerClasses(this.args.product, dataKey);
            const cellClasses = this.cellClasses(this.args.product, dataKey);
            return {
                value,
                valuePath: dataKey,
                component: 'basic-cells/sortable',
                comparerKey: comparersForColumns[dataKey],
                cellComponent: isTimeColumn ? 'basic-cells/duration-unit' : undefined,
                durationConfig: isTimeColumn ? durationConfig : undefined,
                formatEmptyConfig: replaceZeroColumn ? {
                    replaceZero: true
                } : undefined,
                classNames: `table-box table-box-header ${headerClasses}`,
                cellClassNames: `table-box table-box-cell ${cellClasses}`
            };
        });
    }
    /**
   * Returns an object that maps data keys to comparer keys, which tell the sorting system how to sort each column
   */ get comparersForColumns(): Partial<Record<DataKey, string>> {
        return {
            schoolName: 'sortByValue',
            studentCount: 'sortByNumber',
            totalTime: 'sortByNumber',
            lessonsCompleted: 'sortByNumber',
            averageTotalTimePerStudent: 'sortByNumber',
            averageLessonsCompletedPerStudent: 'sortByNumber',
            averageLexileGrowth: 'sortByNumber'
        };
    }
    /**
   * Defines how the durations should be formatted
   */ get durationConfig() {
        const splitDurationOptions = {
            max: Interval.Hours,
            min: Interval.Minutes,
            maxIntervals: 2
        };
        const dataInterval = 'seconds';
        return {
            dataInterval,
            splitDurationOptions
        };
    }
    /**
   * Obtain extra class names for the column header, cell or footer cell
   */ headerClasses(product: ReportingProductSlug, dataKey: DataKey) {
        const defaultClass = product === 'maths' ? 'bg-ms-green-100' : 'bg-oceany-blue-100';
        const mapping: Partial<Record<DataKey, string>> = {
            totalTime: 'data-column bg-purply-pink-350 text-white',
            lessonsCompleted: 'data-column bg-juicy-orange-350 text-white',
            averageTotalTimePerStudent: 'data-column bg-oceany-blue-300 text-white',
            averageLessonsCompletedPerStudent: 'data-column bg-ms-green-350 text-white',
            averageLexileGrowth: 'data-column bg-storm-blue-350 text-white'
        };
        return mapping[dataKey] || defaultClass;
    }
    cellClasses(product: ReportingProductSlug, dataKey: DataKey) {
        const colorClass = product === 'maths' ? 'bg-ms-green-50 group-hover:bg-ms-green-100' : 'bg-oceany-blue-50 group-hover:bg-oceany-blue-100';
        const mapping: Partial<Record<DataKey, string>> = {
            totalTime: 'text-center',
            lessonsCompleted: 'text-center',
            averageTotalTimePerStudent: 'text-center',
            averageLessonsCompletedPerStudent: 'text-center',
            averageLexileGrowth: 'text-center'
        };
        const customClass = mapping[dataKey] || '';
        return `${colorClass} ${customClass}`;
    }
    footerCellClasses(product: ReportingProductSlug, dataKey: DataKey) {
        const defaultClass = 'table-footer-cell border-t print:border-black';
        const colorClass = product === 'maths' ? 'border-ms-green-100' : 'border-oceany-blue-100';
        const mapping: Partial<Record<DataKey, string>> = {
            totalTime: 'text-center',
            lessonsCompleted: 'text-center',
            averageTotalTimePerStudent: 'text-center',
            averageLessonsCompletedPerStudent: 'text-center',
            averageLexileGrowth: 'text-center'
        };
        const customClass = mapping[dataKey] || '';
        return `${defaultClass} ${colorClass} ${customClass}`;
    }
    /**
   * Map each teacher to a row object, each row containing the keys (with data) that match what the column will use to
   * retrieve it
   */ get rows() {
        const schools = this.args.schools || [];
        const schoolsData = this.args.schoolsData || [];
        const { dataKeys } = this;
        return schools.map(({ id, name: schoolName })=>{
            const data = schoolsData.find(({ school_id })=>school_id === Number(id));
            // We don't need any per-cell level configuration, so just use an empty array of cellObjects
            const cells = Array(dataKeys.length).fill({});
            if (!data) {
                return {
                    schoolName,
                    classNames: 'table-row-striped group',
                    cells
                };
            }
            return {
                schoolName,
                studentCount: data.student_count,
                totalTime: data.total_time,
                lessonsCompleted: data.lessons_completed,
                averageTotalTimePerStudent: data.average_total_time_per_student,
                averageLessonsCompletedPerStudent: data.average_lessons_completed_per_student,
                averageLexileGrowth: data.average_lexile_growth,
                // classes for row striping and hovering the row of cells as a group (via tailwind group-hover)
                classNames: 'table-row-striped group',
                cells
            };
        });
    }
    /**
   * This returns an object of data keys that map to cellObjects for the average row of the footer
   */ get averageCellsForColumns(): Partial<Record<DataKey, CellObject>> {
        const averagesAndTotalsData: Partial<AveragesAndTotalsData> = this.args.averagesAndTotalsData ?? {};
        const { durationConfig, intl } = this;
        return {
            schoolName: {
                value: intl.t(`reporting.district.summaryReportTitles.${this.args.product}.average`)
            },
            studentCount: {
                value: intl.t('reporting.district.summaryReportTitles.na')
            },
            totalTime: {
                component: 'basic-cells/duration-unit',
                durationConfig,
                value: averagesAndTotalsData.average_total_time_per_school
            },
            lessonsCompleted: {
                value: averagesAndTotalsData.average_lessons_completed_per_school
            },
            averageTotalTimePerStudent: {
                component: 'basic-cells/duration-unit',
                durationConfig,
                value: averagesAndTotalsData.average_total_time_per_student
            },
            averageLessonsCompletedPerStudent: {
                value: averagesAndTotalsData.average_lessons_completed_per_student
            },
            averageLexileGrowth: {
                value: averagesAndTotalsData.average_lexile_growth
            }
        };
    }
    /**
   * This returns an object of data keys that map to cellObjects for the totals row of the footer
   */ get totalCellsForColumns(): Partial<Record<DataKey, CellObject>> {
        const averagesAndTotalsData: Partial<AveragesAndTotalsData> = this.args.averagesAndTotalsData ?? {};
        const { durationConfig, intl } = this;
        return {
            schoolName: {
                value: intl.t(`reporting.district.summaryReportTitles.${this.args.product}.totals`)
            },
            studentCount: {
                value: averagesAndTotalsData.student_count
            },
            totalTime: {
                component: 'basic-cells/duration-unit',
                durationConfig,
                value: averagesAndTotalsData.total_time
            },
            lessonsCompleted: {
                value: averagesAndTotalsData.lessons_completed
            },
            averageTotalTimePerStudent: {
                value: intl.t('reporting.district.summaryReportTitles.na')
            },
            averageLessonsCompletedPerStudent: {
                value: intl.t('reporting.district.summaryReportTitles.na')
            },
            averageLexileGrowth: {
                value: intl.t('reporting.district.summaryReportTitles.na')
            }
        };
    }
    /**
   * This returns an array of rowObjects to display in the footer of the table
   */ get footerRows() {
        const { dataKeys, averageCellsForColumns, totalCellsForColumns } = this;
        const averageCells = dataKeys.map((dataKey)=>{
            const cell = averageCellsForColumns[dataKey] ?? {};
            cell.classNames = this.footerCellClasses(this.args.product, dataKey);
            return cell;
        });
        const averageRow = {
            cells: averageCells
        };
        const totalsCells = dataKeys.map((dataKey)=>{
            const cell = totalCellsForColumns[dataKey] ?? {};
            cell.classNames = this.footerCellClasses(this.args.product, dataKey);
            return cell;
        });
        const totalsRow = {
            cells: totalsCells
        };
        return [
            averageRow,
            totalsRow
        ];
    }
    static{
        template_f7d66336c69a47ea84153bc8791d3bf3(`
    <div class="__summary-tables__schools__5a8be">
      <BaseTableActions
        @items={{this.rows}}
        @columns={{this.columns}}
        @sortingConfig={{this.sortingConfig}}
        @onSortClick={{this.onSortClick}}
        as |Table|
      >
        <div class="scrollable-table-body overflow-x-auto">
          <Table exportable data-test-summary-table @footerRows={{this.footerRows}} as |tbl|>
            <tbl.head />
            <tbl.body />
            <tbl.foot class="print:border-t-2 print:border-black" />
          </Table>
        </div>
      </BaseTableActions>
      <div
        class="footer-message border-t bg-white p-2 font-bold
          {{if (eq @product 'maths') 'border-ms-green-100' 'border-oceany-blue-100'}}
          print:border-none"
      >
        {{t "reporting.district.summaryReportTitles.footerMessage"}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default SummaryTableSchools;
