import { template as template_41a24f43518d418790732364ea3c386d } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { waitFor } from '@ember/test-waiters';
import { timeout, task } from 'ember-concurrency';
import { tableColumnsToComparerKeyMapping, injectComparerKeys, updateSortColumns, type SortingConfig } from '@blakeelearning/data-tables/utils/sorting';
import applyTransform from '@blakeelearning/data-tables/modifiers/apply-transform';
import type { WithBoundArgs } from '@glint/template';
import BasicTable from '@blakeelearning/stable-table/components/basic-table';
import type DataTransformerService from '@blakeelearning/data-tables/services/data-transformer';
import type { IntlService } from 'ember-intl';
import { hash, array } from '@ember/helper';
import { or, and } from 'ember-truth-helpers';
import { isEmpty } from '@ember/utils';
import type { PaginationConfig } from '@blakeelearning/data-tables/utils/paging';
import type { RowId, RowObject } from '@blakeelearning/stable-table/components/basic-table/cell';
import type { FilterConfig, FilterDataItem } from '@blakeelearning/data-tables/utils/filtering';
import type { PipelineDescriptor } from '@blakeelearning/data-tables/utils/data-transformer';
/**
 * This component combines pipelines from @blakeelearning/data-tables and BasicTable from @blakeelearning/stable-table
 *
 * It aims to remove the bulk of the boilerplate that was usually required to get a consistent implementation for
 * sorting/paging/selection/filtering, and doing so in a modern ember friendly way.
 *
 * Most args are optional. The args you need to provide depend on the table features you need - this component will
 * ensure they all work together nicely.
 *
 * Required:
 *  items - one per row of data.
 *  columns - the columns definition. Dictates how to display the items. You will likely want to consult existing
 *            implementations or the stable-table package for more information on defining this.
 *
 * Sorting:
 *  sortingConfig - how the data should be sorted (columns to sort & direction of each)
 *  onSortClick - called with new sortingConfig when a sort header is clicked
 * Paging:
 *  paginationConfig - how the data should be paged (page number & count)
 *  onPageClick - called with new paginationConfig when a page number is clicked
 * Selecting:
 *  selectedItemIds - an array of ids that match an `id` key on your items
 *  onSelectClick - called with the new array of selected ids when selection is changed
 * Filtering:
 *  filterConfigs - an array of item filter predicates that will filter the provided items.
 *                  Note - this component does not adjust any filtering, it only consumes the given filters.
 *
 * This component will yield a BasicTable component for you to render, a state object and a paging object.
 *
 * An example
 *
  <Base::TableActions
    @items={{this.studentItems}}
    @columns={{this.columns}}
    ...
    as |Table state paging|
  >
    // Adjust the filters yourself however you want. They should result in new filterConfigs provided to the table.
    <input value={{this.searchFilter}} {{on "change" @onSearchInput}} />

    // Table component that is correctly configured for you
    <Table class="border border-red" as |tbl|>
      ...
    </Table>

    // Using the yielded paging object for pagination
    {{#if paging.pageCount}}
      <Base::PageSelector
        @pageCount={{paging.pageCount}}
        @currentPage={{paging.pageNumber}}
        @changePage={{paging.changePage}}
      />
    {{/if}}
  </Base::TableActions>
 */ type SortObject = {
    sortKey: keyof RowObject;
};
interface TransformedData<DataItem extends FilterDataItem> {
    items: DataItem[];
    paginated?: DataItem[];
    pageCount?: number;
}
interface Column {
    label?: string;
    valuePath?: string;
    sortable?: string;
    formatAsList?: boolean;
    formatAsDate?: boolean;
    selectable?: boolean;
    isDownloadButton?: boolean;
}
interface Signature<DataItem extends FilterDataItem> {
    Args: {
        items: DataItem[];
        columns: Column[];
        sortingConfig?: SortingConfig;
        onSortClick?: (sortingConfig: SortingConfig) => void;
        paginationConfig?: PaginationConfig;
        onPageClick?: (paginationConfig: PaginationConfig) => void;
        selectedItemIds?: RowId[];
        filterConfigs?: Nullable<FilterConfig<DataItem>>[];
        onSelectClick?: (selectedIds: RowId[]) => void;
        footerRows?: RowObject[];
    };
    Blocks: {
        default: [WithBoundArgs<typeof BasicTable, 'columns' | 'config' | 'rows' | 'footerRows'>, {
                rows: DataItem[];
                isTransforming: boolean;
                isEmpty: boolean;
                isEmptyAndIdle: boolean;
            }, {
                pageCount?: number;
                pageNumber?: number;
                changePage?: (pageNumber: number) => void;
            }];
    };
}
export class BaseTableActions<DataItem extends FilterDataItem> extends Component<Signature<DataItem>> {
    @service
    dataTransformer: DataTransformerService;
    @service
    intl: IntlService;
    @tracked
    transformedData: TransformedData<DataItem> = {
        items: [],
        paginated: [],
        pageCount: 0
    };
    get pipelineDescriptors() {
        return [
            this.sortingDescriptor,
            ...this.filterDescriptors,
            this.paginationDescriptor
        ];
    }
    get sortingDescriptor() {
        const { sortingConfig, columns } = this.args;
        if (!sortingConfig) return null;
        const comparerKeyMapping = tableColumnsToComparerKeyMapping(columns);
        const sortingConfigWithComparerKeys = injectComparerKeys(sortingConfig, comparerKeyMapping);
        const sortDescriptor = {
            type: 'sort',
            config: sortingConfigWithComparerKeys
        };
        return sortDescriptor;
    }
    get paginationDescriptor() {
        const { paginationConfig } = this.args;
        if (!paginationConfig) return null;
        return {
            type: 'paginate',
            config: paginationConfig
        };
    }
    get filterDescriptors() {
        const { filterConfigs } = this.args;
        if (!filterConfigs) return [];
        return filterConfigs.filter(isPresent).map((filterConfig: FilterConfig<DataItem>)=>{
            // modify the filter function to also include selected items
            const itemFilter = (item: DataItem)=>this.isItemSelected(item) || filterConfig.itemFilter(item);
            const config = {
                ...filterConfig,
                itemFilter
            };
            return {
                type: 'filter',
                config
            };
        });
    }
    // Returns true if the given item has an id that is in the array of selected item ids
    // This is used in the transform/filtering process (as selected items remain unfiltered)
    // Because of this, it must be based on original selected item ids from args, rather than result of the transformation
    isItemSelected = (item: FilterDataItem)=>{
        return this.args.selectedItemIds?.includes(item.id as RowId);
    };
    performTransformItems = (_element: unknown, [items, pipelineDescriptors]: [DataItem[], PipelineDescriptor[]])=>{
        void this.transformItems.perform(items, pipelineDescriptors);
    };
    transformItems = task({
        restartable: true
    }, waitFor(async (items: DataItem[] = [], pipelineDescriptors: PipelineDescriptor[] = [])=>{
        await timeout(200) // debounce
        ;
        const presentDescriptors = pipelineDescriptors.filter(isPresent);
        this.transformedData = await this.dataTransformer.buildAndTransform<DataItem, DataItem>(items, presentDescriptors);
    }));
    // items after applying all transformations (post-filter, single page)
    get transformedItems() {
        return this.transformedData.items;
    }
    // items within scope of the select all checkbox (post-filter, pre-paging)
    get unpaginatedItems() {
        return this.transformedData.paginated?.flat() ?? this.transformedData.items;
    }
    get allSelectableIds(): RowId[] {
        return this.unpaginatedItems.map((item: FilterDataItem)=>item.id as RowId);
    }
    get pageCount() {
        return this.transformedData.pageCount;
    }
    changeSort = (key: SortObject)=>{
        // adds to the config or flips the sort direction
        if (!this.args.sortingConfig) return;
        const sortingConfig = updateSortColumns(this.args.sortingConfig, key.sortKey as string);
        this.args.onSortClick?.(sortingConfig);
    };
    changePage = (pageNumber: number)=>{
        if (!this.args.paginationConfig) return;
        const paginationConfig = {
            pageNumber,
            perPage: this.args.paginationConfig.perPage
        };
        this.args.onPageClick?.(paginationConfig);
    };
    static{
        template_41a24f43518d418790732364ea3c386d(`
    <span {{applyTransform this.performTransformItems @items this.pipelineDescriptors}}></span>
    {{yield
      (component
        BasicTable
        columns=@columns
        rows=this.transformedItems
        config=(hash
          sortingConfig=@sortingConfig
          onSortClick=this.changeSort
          onSelectClick=@onSelectClick
          selectedIds=(or @selectedItemIds (array))
          allSelectableIds=this.allSelectableIds
        )
      )
      (hash
        rows=this.transformedItems
        isTransforming=this.transformItems.isRunning
        isEmpty=(isEmpty this.transformedItems)
        isEmptyAndIdle=(and this.transformItems.isIdle (isEmpty this.transformedItems))
      )
      (hash pageCount=this.pageCount pageNumber=@paginationConfig.pageNumber changePage=this.changePage)
    }}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default BaseTableActions;
