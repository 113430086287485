import { template as template_beeb614770d740858053e88a173e9e3b } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { on } from '@ember/modifier';
import { UiButton } from 'district-ui-client/components/ui-button';
import { UiButtonState } from 'district-ui-client/components/ui-button/state';
import { or } from 'ember-truth-helpers';
interface Signature {
    Args: {
        negativeAction?: () => void;
        negativeButtonText?: string;
        positiveAction?: () => void;
        positiveButtonText: string;
        isTaskLoading?: boolean;
        positiveButtonAppearance?: string;
        positiveButtonDisabled?: boolean;
    };
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export const CleverUiConfirmDialog: TOC<Signature> = template_beeb614770d740858053e88a173e9e3b(`
  <div ...attributes>
    <div class="mx-auto my-0 w-full max-w-[32rem] p-7 text-center">
      {{yield}}
    </div>
    <div class="border-t-dusty-black-50 border-t border-solid px-4 pb-0.5 pt-3 text-center">
      {{#if @negativeAction}}
        <UiButton
          data-test-confirm-dialog-negative
          class="muted"
          disabled={{@isTaskLoading}}
          {{on "click" @negativeAction}}
        >
          {{@negativeButtonText}}
        </UiButton>
      {{/if}}
      {{#if @positiveAction}}
        <UiButton
          data-test-confirm-dialog-positive
          class="{{or @positiveButtonAppearance 'regular'}}"
          disabled={{or @isTaskLoading @positiveButtonDisabled}}
          {{on "click" @positiveAction}}
        >
          <UiButtonState @isLoading={{@isTaskLoading}}>{{@positiveButtonText}}</UiButtonState>
        </UiButton>
      {{/if}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CleverUiConfirmDialog;
