import { template as template_899faa1fdd194db69114ef3d81c07637 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
export const DividerHorizontal: TOC<{
    Element: HTMLHRElement;
}> = template_899faa1fdd194db69114ef3d81c07637(`
  <hr class="h-[2px] bg-neutral-50" ...attributes />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
