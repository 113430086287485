import { template as template_14bd11ede828406ba960c871cef1fe89 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type CleverTeacher from 'district-ui-client/models/clever/clever-teacher';
import { type ErrorCode, ErrorCodes } from 'district-ui-client/services/clever/teacher-match-error';
import { eq } from 'ember-truth-helpers';
import { ExistsInDifferentDistrictTeacherMatcherModal } from 'district-ui-client/components/clever-ui/modals/teacher-matcher/exists-in-different-district';
import { ExistsAsParentTeacherMatcherModal } from 'district-ui-client/components/clever-ui/modals/teacher-matcher/exists-as-parent';
import { ExistsAsParentContactTeacherMatcherModal } from 'district-ui-client/components/clever-ui/modals/teacher-matcher/exists-as-parent-contact';
import { IdMismatchErrorTeacherMatcherModel } from 'district-ui-client/components/clever-ui/modals/teacher-matcher/id-mismatch-error';
import { StaleAccountErrorTeacherMatcherModal } from 'district-ui-client/components/clever-ui/modals/teacher-matcher/stale-account-error';
import { InvalidEmailCharTeacherMatcherModal } from 'district-ui-client/components/clever-ui/modals/teacher-matcher/invalid-email-char';
import { UnknownErrorTeacherMatcherModal } from 'district-ui-client/components/clever-ui/modals/teacher-matcher/unknown';
interface ModalSignature {
    Args: {
        errorCode?: ErrorCode;
        isShowingModal?: boolean;
        cleverTeacher: CleverTeacher;
        positiveAction: () => void;
        negativeAction: () => void;
    };
}
export const TeacherMatchErrorModals: TOC<ModalSignature> = template_14bd11ede828406ba960c871cef1fe89(`
  {{#if (eq @errorCode ErrorCodes.EXISTS_IN_DIFFERENT_DISTRICT)}}
    <ExistsInDifferentDistrictTeacherMatcherModal
      @cleverTeacher={{@cleverTeacher}}
      @isShowingModal={{@isShowingModal}}
      @positiveAction={{@positiveAction}}
      @negativeAction={{@negativeAction}}
    />

  {{else if (eq @errorCode ErrorCodes.EXISTS_AS_PARENT)}}
    <ExistsAsParentTeacherMatcherModal
      @cleverTeacher={{@cleverTeacher}}
      @isShowingModal={{@isShowingModal}}
      @positiveAction={{@positiveAction}}
      @negativeAction={{@negativeAction}}
    />

  {{else if (eq @errorCode ErrorCodes.EXISTS_AS_PARENT_CONTACT)}}
    <ExistsAsParentContactTeacherMatcherModal
      @cleverTeacher={{@cleverTeacher}}
      @isShowingModal={{@isShowingModal}}
      @positiveAction={{@positiveAction}}
      @negativeAction={{@negativeAction}}
    />

  {{else if (eq @errorCode ErrorCodes.ID_MISMATCH_ERROR)}}
    <IdMismatchErrorTeacherMatcherModel
      @cleverTeacher={{@cleverTeacher}}
      @isShowingModal={{@isShowingModal}}
      @positiveAction={{@positiveAction}}
      @negativeAction={{@negativeAction}}
    />

  {{else if (eq @errorCode ErrorCodes.STALE_ACCOUNT_ERROR)}}
    <StaleAccountErrorTeacherMatcherModal
      @cleverTeacher={{@cleverTeacher}}
      @isShowingModal={{@isShowingModal}}
      @positiveAction={{@positiveAction}}
      @negativeAction={{@negativeAction}}
    />

  {{else if (eq @errorCode ErrorCodes.INVALID_EMAIL_CHAR)}}
    <InvalidEmailCharTeacherMatcherModal
      @cleverTeacher={{@cleverTeacher}}
      @isShowingModal={{@isShowingModal}}
      @positiveAction={{@positiveAction}}
      @negativeAction={{@negativeAction}}
    />

  {{else}}
    <UnknownErrorTeacherMatcherModal
      @cleverTeacher={{@cleverTeacher}}
      @isShowingModal={{@isShowingModal}}
      @positiveAction={{@positiveAction}}
      @negativeAction={{@negativeAction}}
    />

  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TeacherMatchErrorModals;
