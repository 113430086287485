import { template as template_41b0a720086d451faeb3581329d2fcd3 } from "@ember/template-compiler";
import { type SafeString } from '@ember/template';
import Component from '@glimmer/component';
import isEqual from 'lodash/isEqual';
import { on } from '@ember/modifier';
import { renderTemplateToString } from 'district-ui-client/components/form-components/form-select';
import { get } from '@ember/object';
import type Model from '@ember-data/model';
import type SchoolClass from 'district-ui-client/models/school-class';
export interface SelectOption extends Record<string, unknown> {
    isStatic?: boolean;
    parentId?: string;
    text: string;
    uid?: string;
    value?: OptionValue;
}
export function isSelectOption(obj: unknown): obj is SelectOption {
    return Boolean(obj && typeof obj === 'object' && 'uid' in obj && 'text' in obj && typeof obj.uid === 'string' && typeof obj.text === 'string');
}
interface Args {
    keySelectedOptionValue: unknown;
    listClickAction: (args: Args) => void;
    option: SelectOption;
    optionIndex: string;
    optionTemplate: SafeString | string;
    optionTemplateSubLevel: SafeString | string;
    parentId: string;
    secondLevelOnly?: boolean;
    sub?: boolean;
    value?: SelectOption | string | number;
    valueLookupKey?: ValueKey;
}
interface Signature {
    Args: Args;
}
export function option(record: Model, { uidKey = 'id', textKey = 'name' } = {}): SelectOption {
    const uid = get(record, uidKey) as string;
    const text = get(record, textKey) as string;
    return {
        text,
        uid,
        value: uid
    };
}
export function groupOption(record: Model, { uidKey = 'id', textKey = 'name' } = {}): SelectOption {
    const uid = get(record, uidKey) as string;
    const text = get(record, textKey) as string;
    return {
        text,
        uid,
        value: []
    };
}
export interface SelectOptionWithStudentCount extends SelectOption {
    studentCount: number;
}
export function getSchoolClassAsOption(schoolClass: SchoolClass): SelectOptionWithStudentCount {
    return {
        ...option(schoolClass),
        studentCount: schoolClass.students?.length ?? 0
    };
}
export function isOptionWithStudentCount(option: SelectOption): option is SelectOptionWithStudentCount {
    return (typeof option.value === 'object' && 'studentCount' in option.value && typeof option.value.studentCount === 'number');
}
export type ValueKey = 'uid' | 'value';
export type OptionValue = number | string | SelectOption[] | SelectOption;
export default class FormSelectOption extends Component<Signature> {
    get isArray() {
        return Array.isArray(this.args.option.value);
    }
    get optionArray() {
        return Array.isArray(this.args.option.value) ? this.args.option.value : [];
    }
    /**
   * Check if we have the secondLevelOnly prop set, also check if the option is static
   * and therefore can't be disabled.
   *
   */ get isDisabled() {
        const { secondLevelOnly } = this.args;
        const isStatic = this.args.option.isStatic || false;
        return secondLevelOnly && !isStatic;
    }
    /**
   * depending on the valueLookupKey option, the value can be either an option object
   * or a simple value like an in or string.
   */ get activeClass() {
        let value;
        if (typeof this.args.value === 'string' || typeof this.args.value === 'number') {
            value = this.args.value;
        } else {
            value = this.args.value?.uid || this.args.value;
        }
        // use the uid whenever we can otherwise use the entire object
        let optionValue: OptionValue | undefined = this.args.option?.uid || this.args.option;
        if (this.args.valueLookupKey) optionValue = this.args.option?.[this.args.valueLookupKey];
        if (isEqual(value, optionValue)) return 'selected';
        return undefined;
    }
    get optionValue() {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        return this.args.option.uid || String(this.args.option.value);
    }
    get preselectedClass() {
        if (this.args.keySelectedOptionValue === null) return undefined;
        const isKeyselectedOption = isEqual(this.args.keySelectedOptionValue, this.args.option);
        if (isKeyselectedOption) return 'pre-selected';
        return '';
    }
    get templatedOption() {
        return renderTemplateToString(this.args.optionTemplate, this.args.option);
    }
    get templatedOptionSubLevel() {
        return renderTemplateToString(this.args.optionTemplateSubLevel, this.args.option);
    }
    optionClick = ()=>{
        if (this.isDisabled) return;
        this.args.listClickAction(this.args);
    };
    static{
        template_41b0a720086d451faeb3581329d2fcd3(`
    {{#if this.isArray}}
      <li class="child" data-test-form-select-option-id={{this.optionValue}}>
        <strong
          data-test-form-select-option-list-title
          id="{{@parentId}}_{{@optionIndex}}"
          class="{{this.activeClass}} {{this.preselectedClass}} {{if this.isDisabled 'disabled'}}"
          data-value={{this.optionValue}}
          data-id={{this.optionValue}}
          data-index={{@optionIndex}}
          role="button"
          {{on "click" this.optionClick}}
        >
          {{#if @sub}}
            {{this.templatedOptionSubLevel}}
          {{else}}
            {{this.templatedOption}}
          {{/if}}
        </strong>
        <ul>
          {{#each this.optionArray as |o index|}}
            <FormSelectOption
              @option={{o}}
              @listClickAction={{@listClickAction}}
              @value={{@value}}
              @keySelectedOptionValue={{@keySelectedOptionValue}}
              @parentId={{@parentId}}
              @sub={{true}}
              @optionIndex="{{@parentId}}_{{@optionIndex}}_{{index}}"
              @optionTemplate={{@optionTemplate}}
              @optionTemplateSubLevel={{@optionTemplateSubLevel}}
              @valueLookupKey={{@valueLookupKey}}
            />
          {{/each}}
        </ul>
      </li>
    {{else}}
      <li
        data-test-form-select-option-id={{this.optionValue}}
        id="{{@parentId}}_{{@optionIndex}}"
        class="{{this.activeClass}} {{this.preselectedClass}} {{if this.isDisabled 'disabled'}}"
        data-value={{this.optionValue}}
        data-id={{this.optionValue}}
        data-index={{@optionIndex}}
        role="button"
        {{on "click" this.optionClick}}
      >
        {{#if @sub}}
          {{this.templatedOptionSubLevel}}
        {{else}}
          {{this.templatedOption}}
        {{/if}}
      </li>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
