import { template as template_34b4b6edf4f34cf39bc0c4e3921ab59c } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export const ModalDefaultBody: TOC<Signature> = template_34b4b6edf4f34cf39bc0c4e3921ab59c(`
  <div class="relative overflow-y-auto p-3 text-center" ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalDefaultBody;
