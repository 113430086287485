import { template as template_5d283654d13841f1a1485a13a746a7a8 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { reportingLegend, reportingPlotLine } from 'district-ui-client/utils/giraffe/highcharts-config';
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format';
import { gradePositionFromLesson, lessonRangeFromGradePosition } from 'district-ui-client/utils/giraffe/lesson-grade-map';
import BlakeColors from '@blakeelearning/blake-colours/colours';
import type GradeSetsService from 'district-ui-client/services/grade-sets';
import type { ReportingProductSlug } from 'district-ui-client/domain/product';
import type { Point, SeriesLineOptions, AxisLabelsFormatterCallbackFunction, YAxisPlotLinesOptions } from 'highcharts';
import { TooltipInfo } from 'district-ui-client/components/tooltip';
import SeriesLine from 'district-ui-client/components/primitives/series-line/component';
/**
 * Format the value based on the current precinct.
 */ function valueFormatter(value: number | string, product: ReportingProductSlug, precinct: string) {
    if (product === 'rex' && precinct === 'spelling') {
        let v = value;
        if (typeof v === 'string') {
            v = parseInt(v);
        }
        const maxLessons = 36;
        const grade = Math.ceil(v / maxLessons);
        const lesson = `0${v % maxLessons}`.slice(-2).replace('00', maxLessons.toString());
        return `${grade}.${lesson}`;
    }
    return String(value);
}
/**
 * Puts together all the pieces for the course progress averages chart.
 *
 * Data format:
 *
 * ```
 * {
 *   grade_position: 1,
 *   months: [
 *     {
 *       avg_max_lesson: 50,
 *       month: '2016-07',
 *     },
 *     {
 *       avg_max_lesson: 70,
 *       month: '2016-08',
 *     },
 *   ],
 * }
 * ```
 */ interface CourseProgressMonthData {
    avg_max_lesson: number;
    month: string;
}
export interface CourseProgressAveragesData {
    grade_position: number;
    months: CourseProgressMonthData[];
}
interface Signature {
    Args: {
        title: string;
        data: CourseProgressAveragesData;
        precinct: 'lessons' | 'reading' | 'spelling' | 'my_lessons';
        product: 'maths' | 're' | 'rex';
        tooltip?: string;
    };
    Element: HTMLDivElement;
}
export class ChartsCourseProgressAverages extends Component<Signature> {
    @service
    gradeSets: GradeSetsService;
    get hyphenatedTitle() {
        const mainChartTitle = this.args.title;
        const gradePosition = this.args.data.grade_position;
        if (typeof gradePosition !== 'number') return `${mainChartTitle}`;
        const gradeSuffix = this.gradeSets.findByPosition(gradePosition)?.fullName;
        return `${mainChartTitle} - ${gradeSuffix}`;
    }
    /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */ get chartData(): SeriesLineOptions[] {
        const data = this.args.data.months?.map((monthData, index)=>({
                x: index,
                y: monthData.avg_max_lesson,
                monthData
            })) ?? [];
        return [
            {
                name: 'avg_max_lesson',
                color: this.color,
                data,
                type: 'line'
            }
        ];
    }
    get color() {
        const { product, precinct } = this.args;
        switch(`${product}.${precinct}`){
            case 'maths.lessons':
                return BlakeColors.forestGreen300;
            case 're.spelling':
            case 'rex.spelling':
                return BlakeColors.grapeyGreen300;
            case 're.reading':
            case 'rex.my_lessons':
                return BlakeColors.oceanyBlue300;
            default:
                return BlakeColors.oceanyBlue300;
        }
    }
    /**
   * Computed list of category data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */ get categories() {
        return this.args.data.months?.map((m)=>convertDateFormat(m.month)) ?? [];
    }
    get legend() {
        return reportingLegend('left', 'top', 50, 10);
    }
    /**
   * Plot lines to mark the grade bands for the lesson values.
   */ get plotLines(): YAxisPlotLinesOptions[] {
        const { product, precinct } = this.args;
        const months = this.args.data.months ?? [];
        const lessons = months.map((m)=>m.avg_max_lesson).filter((avgMaxLesson)=>!isEmpty(avgMaxLesson));
        if (isEmpty(lessons)) return [];
        const grades = lessons.map((lesson)=>gradePositionFromLesson(product, precinct, lesson)).filter((grade): grade is number =>typeof grade === 'number');
        // Add the _next_ grade to the list
        const uniqueGrades = [
            ...new Set(grades)
        ];
        uniqueGrades.push(grades[grades.length - 1] + 1);
        const plotLines = uniqueGrades.map((grade)=>{
            const { min: lesson } = lessonRangeFromGradePosition(product, precinct, grade) as {
                min?: number;
            };
            const label = this.gradeSets.findByPosition(grade)?.fullName ?? '';
            if (typeof lesson === 'number') return reportingPlotLine(lesson, label);
            return null;
        });
        return plotLines.filter((item): item is YAxisPlotLinesOptions =>item !== null);
    }
    /**
   * Returns a formatter function to pass to Highcharts to format the tooltip label.
   */ get toolTipFormatter() {
        const { product, precinct } = this.args;
        return function(this: Point) {
            return typeof this.y === 'number' ? `<strong>${valueFormatter(this.y, product, precinct)}</strong>` : '';
        };
    }
    /**
   * Returns a formatter function to pass to Highcharts to format the y axis label.
   */ get yAxisLabelFormatter(): AxisLabelsFormatterCallbackFunction {
        const { product, precinct } = this.args;
        return function() {
            return valueFormatter(this.value, product, precinct);
        };
    }
    /**
   * Set the yRange of the chart to include all lessons for all grades in the data
   */ get yRange() {
        const { product, precinct } = this.args;
        const months = this.args.data.months ?? [];
        const grades = months.reduce<number[]>((acc, monthData)=>{
            if (monthData.avg_max_lesson) {
                const grade = gradePositionFromLesson(product, precinct, monthData.avg_max_lesson);
                return typeof grade !== 'number' || acc.includes(grade) ? acc : [
                    ...acc,
                    grade
                ];
            }
            return acc;
        }, []);
        // map returns [ {min, max}, {min, max}]
        const allLessons = grades.map((grade)=>lessonRangeFromGradePosition(product, precinct, grade)).reduce<number[]>((prev, curr)=>{
            const { min, max } = curr;
            return prev.concat(min, max);
        }, []).sort((a, b)=>a - b);
        if (isEmpty(allLessons)) return [
            -Infinity,
            Infinity
        ];
        // add one lesson to ensure the next grade plotLine is shown for context
        allLessons.push(allLessons[allLessons.length - 1] + 1);
        return [
            allLessons[0],
            allLessons[allLessons.length - 1] + 1
        ];
    }
    get chartSpacing(): [number, number, number, number] {
        return [
            10,
            10,
            10,
            10
        ];
    }
    static{
        template_5d283654d13841f1a1485a13a746a7a8(`
    <div data-test-course-progress-averages class="relative rounded-md bg-white" ...attributes>
      <TooltipInfo class="z-tooltip absolute right-3 top-3 text-xs print:hidden" @text={{@tooltip}} />
      <SeriesLine
        @title={{this.hyphenatedTitle}}
        @data={{this.chartData}}
        @categories={{this.categories}}
        @chartSpacing={{this.chartSpacing}}
        @yAxisLabel="Lesson number"
        @yAxisLabelFormatter={{this.yAxisLabelFormatter}}
        @toolTipFormatter={{this.toolTipFormatter}}
        @plotLines={{this.plotLines}}
        @yRange={{this.yRange}}
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ChartsCourseProgressAverages;
