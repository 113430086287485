import { template as template_72d2cdae83d244a4bac67207a9d85c96 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
interface Signature {
    Args: {
        matchErrorCode: string;
        summaryText: string;
    };
}
export const TeacherMatchErrorSummary: TOC<Signature> = template_72d2cdae83d244a4bac67207a9d85c96(`
  <div data-test-teacher-match-error={{@matchErrorCode}}>
    <FaIcon class="icon text-watermelony-red-300 mr-1" @icon="triangle-exclamation" @size="lg" @fixedWidth={{true}} />
    {{@summaryText}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TeacherMatchErrorSummary;
