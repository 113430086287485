import { template as template_39f73cfa3c20483da0f1b09432353a60 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
export const CleverPage: TOC<unknown> = template_39f73cfa3c20483da0f1b09432353a60(`
  <div class="mx-8 my-10">
    {{outlet}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(CleverPage);
